import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { bindActionCreators } from "redux";
import {
  DatePicker,
  Row,
  Col,
  Typography,
  Select,
  Button,
  Table,
  Input,
  Icon,
  Form,
  Tabs,
} from "antd";
import * as UserService from "../../../../services/user-service";
import IntlMessages from "../../../../services/intlMesseges";
import { ViewCell } from "../../../shared/components/helperCells";
import * as travellersReportActions from "../../../../redux/actions/travellers-report-actions";
import * as PaginationActions from "../../../../redux/actions/pagination-actions.js";
import { CSVLink } from "react-csv";
import * as adminActions from "../../../../redux/actions/admin-actions";
import API from "../../../../redux/api/admin-api";
import PrintComponents from "react-print-components";
import PrintAbleComponent from "../printable-component.jsx";
import * as Permissions from "../../../../services/permissions";
import axios from "axios";
import travellerapi from "../../../../redux/api/travellers-report-api";
import * as UserServices from "../../../../services/user-service";
import { init } from "react-facebook-pixel";

const { Option } = Select;
const { TabPane } = Tabs;
const dateFormat = "MM/DD/YYYY ";
const { RangePicker } = DatePicker;

class TravelersbyDestDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activetravellerList: [],
      activeManualtravellerList: [],
      allactivetravellerList: [],
      countries: [],
      pagination: {
        hideOnSinglePage: true,
        showTotal: (total, range) => {
          return (
            <span>
              <IntlMessages id='admin.text.showing' /> {range[0]}-{range[1]} <IntlMessages id='admin.text.of' /> {total}{' '}
              <IntlMessages id='admin.text.results' />
            </span>
          );
        },
        pageSize: 10,
        current: 1
      },
      filters: {},
      startdate: '',
      enddate: '',
      countryId: '',
      showTable: false,
      formLayout: undefined,
      role: '',
      downloadSelectedValue: 'current',
      csvSearchFilters: {},
      tab: 1,
      clearAllFilters: false,
      filterInputs: {},
      
    };
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.callback = this.callback.bind(this);
    //this.clearSearchFilter = this.clearSearchFilter.bind(this);
  }
  callback(key) {
    this.setState({ 
      clearAllFilters: true,
      filters: {},
      pagination: {
        current: 1,
        pageSize: 10,
      },
      tab: key
    }, () => {
      if (key === '1') {
        this.handleManualTableChange({ current: 1, pageSize: 10 }, {}, {});
      } else {
        this.handleTableChange({ current: 1, pageSize: 10 }, {}, {}, key);
      }
    });
    // return;
    // let filters = this.state.filters;
    // if (filters['name']) {
    //   if (filters['name'].setSelectedKeys && typeof filters['name'].setSelectedKeys === 'function') {
    //     filters['name'].setSelectedKeys('');
    //     filters['name'].confirm();
    //   }
    // }
    // if (filters['divisionName']) {
    //   if (filters['divisionName'].setSelectedKeys && typeof filters['divisionName'].setSelectedKeys === 'function') {
    //     filters['divisionName'].setSelectedKeys('');
    //     filters['divisionName'].confirm();
    //   }
    // }
    // if (filters['parentOrganizationName']) {
    //   if (
    //     filters['parentOrganizationName'].setSelectedKeys &&
    //     typeof filters['parentOrganizationName'].setSelectedKeys === 'function'
    //   ) {
    //     filters['parentOrganizationName'].setSelectedKeys('');
    //     filters['parentOrganizationName'].confirm();
    //   }
    // }
    // if (filters['pnrNumber']) {
    //   if (filters['pnrNumber'].setSelectedKeys && typeof filters['pnrNumber'].setSelectedKeys === 'function') {
    //     filters['pnrNumber'].setSelectedKeys('');
    //     filters['pnrNumber'].confirm();
    //   }
    // }
    // if (filters['city']) {
    //   if (filters['city'].setSelectedKeys && typeof filters['city'].setSelectedKeys === 'function') {
    //     filters['city'].setSelectedKeys('');
    //     filters['city'].confirm();
    //   }
    // }
    // filters['pnrNumber'] = { val: '', clearf: '', filter: false };

    // filters['name'] = { val: '', clearf: '', filter: false };
    // filters['divisionName'] = { val: '', clearf: '', filter: false };
    // filters['parentOrganizationName'] = { val: '', clearf: '', filter: false };
    // filters['pnrNumber'] = { val: '', clearf: '', filter: false };
    // filters['city'] = { val: '', clearf: '', filter: false };
    // this.setState({ filters: filters, searchText: '' });
    
    
  }

  componentWillMount() {
    let currentUser = UserService.getUser();
    let userRole = currentUser.role;
    this.setState({ role: userRole });
  }
  async componentDidMount() {
    let countriesListTemp = await API.getCountriesList();
    var countries = [];
    countriesListTemp.countries.forEach(function(country) {
      if (country.countryName !== 'All Countries') {
        countries.push(country);
      }
    });
    this.setState({ countries: countries });
    // this.props.getCountriesListName().then(data => {
    //   this.setState({ countries: data });
    //   this.setState({ loading: true });
    // })
  }

  
  // clearSearchFilter(dataIndex){
  //   let filters = this.state.filters;
  //   if (filters[dataIndex]) {
  //     if (filters[dataIndex].setSelectedKeys && typeof filters[dataIndex].setSelectedKeys === 'function') {
  //       filters[dataIndex].setSelectedKeys('');
  //       filters[dataIndex].confirm();
  //     }
  //   }
  //   filters[dataIndex] = { val: '', clearf: '', filter: false };
  //   this.setState({ filters: filters });
  //   this.setState({ searchText: '' });
  // };
  
  
  handleSubmit() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        
        this.setState({ 
          clearAllFilters: true,
          filters: {},
          pagination: {
            current: 1,
            pageSize: 10,
          } 
        }, ()=> {
          //  this.clearSearchFilter('divisionName');
          // this.setState({ filters: this.state.initalfilters || {} });
          if (this.state.tab == '1') {
            this.handleManualTableChange({ current: 1, pageSize: 10 }, {}, {});
          } else {
            this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
          }
         
        
          //this.handleTableChange({ current: 1, pageSize: 10 }, {}, {});
          // this.handleManualTableChange({ current: 1, pageSize: 10 }, {}, {});
        });
      }
    });
  }
  //old code
  // handleCountryChange(e) {
  //   if (e === 'all') {
  //     this.setState({ countryId: '' });
  //   } else {
  //     console.log('this.state.countries',this.state.countries);
  //     var countryCode = this.state.countries.filter((data) => data.countryId === e)[0].countryCode;
  //     this.setState({ countryId: countryCode });
  //   }
  // }
  
  
  handleCountryChange(e) {
    if (e === 'all') {
      this.setState({ countryId: '' });
    } else {
      const selectedCountry = this.state.countries.find((data) => data.countryId === e);
      
      if (selectedCountry) {
        this.setState({ countryId: selectedCountry.countryCode });
      } else {
       // console.error(`Country with countryId ${e} not found`);
        // Optionally, you can set a default or handle the error as needed
        this.setState({ countryId: '' });
      }
    }
  }
  
 
  handleStartDateChange(e) {
    if (e !== null && e !== undefined && e.length !== 0) {
      var starDate = moment(e[0]._d).format('YYYY-MM-DD');
      var enDate = moment(e[1]._d).format('YYYY-MM-DD');
      this.setState({ startdate: starDate });
      this.setState({ enddate: enDate });
    }
  }
  handleEndDateChange(e) {
    if (e != null) {
      var changedDate = moment(e._d).format('YYYY-MM-DD');
      this.setState({ enddate: changedDate });
    }
  }
  viewTravelerList = (pnrId, email) => {
    let url;
    if (this.state.tab == 1) {
      url = '../../traveler-manualitinerary-viewer/' + pnrId;
    } else {
      url = '../../traveler-itinerary-viewer/' + pnrId + '/' + email;
    }
    window.open(url);
  };

  getHeaderKeys = () => {
    let _self = this;
    let data = [
      {
        title: <IntlMessages id='admin.userlisting.name' />,
        dataIndex: 'name',
        filteredValue: (typeof _self.state.filterInputs['name'] !== "undefined") ? _self.state.filterInputs['name'] : null,
        ...this.getColumnSearchProps('name')
      },
      {
        title: <IntlMessages id='admin.report.division' />,
        dataIndex: 'divisionName',
        filteredValue: (typeof _self.state.filterInputs['divisionName'] !== "undefined") ? _self.state.filterInputs['divisionName'] : null,
        ...this.getColumnSearchProps('divisionName')
      },
      {
        title: <IntlMessages id='admin.report.pnr' />,
        dataIndex: 'pnrNumber',
        filteredValue: (typeof _self.state.filterInputs['pnrNumber'] !== "undefined") ? _self.state.filterInputs['pnrNumber'] : null,
        ...this.getColumnSearchProps('pnrNumber')
      },
      {
        title: <IntlMessages id='admin.report.destinations' />,
        dataIndex: 'destinations',
        filteredValue: (typeof _self.state.filterInputs['destinations'] !== "undefined") ? _self.state.filterInputs['destinations'] : null,
        // ...this.getColumnSearchProps('destinations')
      },
      {
        title: <IntlMessages id='admin.report.cities' />,
        dataIndex: 'routing'
      },
      {
        title: <IntlMessages id='admin.report.startDate' />,
        dataIndex: 'startDate'
      },
      {
        title: <IntlMessages id='admin.report.endDate' />,
        dataIndex: 'endDate'
      },
      {
        title: <IntlMessages id='admin.report.duration' />,
        dataIndex: 'dayDiff'
      },
      {
        title: <IntlMessages id='admin.report.legStartDate' />,
        dataIndex: 'legStartDate'
      },
      {
        title: <IntlMessages id='admin.report.legEndDate' />,
        dataIndex: 'legEndDate'
      },
      {
        title: <IntlMessages id='admin.report.legDuration' />,
        dataIndex: 'legDuration'
      },
      {
        title: <IntlMessages id='admin.report.countries' />,
        dataIndex: 'countries'
      },

      {
        title: <IntlMessages id='admin.report.routing' />,
        dataIndex: 'routing'
      },
      {
        title: <IntlMessages id='admin.manageOrders.Action' />,
        rowKey: 'action',
        render: (_text, record) => (
          <span>
            <ViewCell
              onViewCell={() => {
                this.viewTravelerList(record.pnrId, record.email);
              }}
            />
          </span>
        )
      }
    ];
    if (Permissions.isRole('CSR') || Permissions.isRole('Administrator')) {
      data.splice(2, 0, {
        title: <IntlMessages id='admin.report.organization' />,
        dataIndex: 'parentOrganizationName',
        filteredValue: (typeof _self.state.filterInputs['parentOrganizationName'] !== "undefined") ? _self.state.filterInputs['parentOrganizationName'] : null,
        ...this.getColumnSearchProps('parentOrganizationName')
      });
    }
    return data;
  };

  getHeaderKeys2 = () => {
    let _self = this;
    let data = [
      {
        title: <IntlMessages id='admin.userlisting.name' />,
        dataIndex: 'name',
        filteredValue: (typeof _self.state.filterInputs['name'] !== "undefined") ? _self.state.filterInputs['name'] : null,
        ...this.getColumnSearchProps('name')
      },
      {
        title: <IntlMessages id='admin.report.division' />,
        dataIndex: 'divisionName',
        filteredValue: (typeof _self.state.filterInputs['divisionName'] !== "undefined") ? _self.state.filterInputs['divisionName'] : null,
        ...this.getColumnSearchProps('divisionName')
      },
      {
        title: <IntlMessages id='admin.report.pnr' />,
        dataIndex: 'pnrNumber',
        ...this.getColumnSearchProps('pnrNumber'),
        filteredValue: (typeof _self.state.filterInputs['pnrNumber'] !== "undefined") ? _self.state.filterInputs['pnrNumber'] : null,
      },
      {
        title: <IntlMessages id='admin.report.destinations' />,
        dataIndex: 'destinations',
        filteredValue: (typeof _self.state.filterInputs['destinations'] !== "undefined") ? _self.state.filterInputs['destinations'] : null,
        // ...this.getColumnSearchProps('destinations')
      },

      {
        title: <IntlMessages id='admin.report.cities' />,
        dataIndex: 'routing'
      },
      {
        title: <IntlMessages id='admin.report.startDate' />,
        dataIndex: 'startDate'
      },
      {
        title: <IntlMessages id='admin.report.endDate' />,
        dataIndex: 'endDate'
      },
      {
        title: <IntlMessages id='admin.report.duration' />,
        dataIndex: 'dayDiff'
      },
      {
        title: <IntlMessages id='admin.report.legStartDate' />,
        dataIndex: 'legStartDate'
      },
      {
        title: <IntlMessages id='admin.report.legEndDate' />,
        dataIndex: 'legEndDate'
      },
      {
        title: <IntlMessages id='admin.report.legDuration' />,
        dataIndex: 'legDuration'
      },
      {
        title: <IntlMessages id='admin.report.countries' />,
        dataIndex: 'countries'
      },
      {
        title: <IntlMessages id='admin.manageOrders.Action' />,
        rowKey: 'action',
        render: (_text, record) => (
          <span>
            <ViewCell
              onViewCell={() => {
                this.viewTravelerList(record.pnrId, record.email);
              }}
            />
          </span>
        )
      }
    ];
    if (Permissions.isRole('CSR') || Permissions.isRole('Administrator')) {
      data.splice(2, 0, {
        title: <IntlMessages id='admin.report.organization' />,
        dataIndex: 'parentOrganizationName',
        filteredValue: (typeof _self.state.filterInputs['parentOrganizationName'] !== "undefined") ? _self.state.filterInputs['parentOrganizationName'] : null,
        ...this.getColumnSearchProps('parentOrganizationName')
      });
    }
    return data;
  };

  getHeaderKeys3 = () => {
    let data = [
      {
        title: <IntlMessages id='admin.userlisting.name' />,
        dataIndex: 'name',
        ...this.getColumnSearchProps('name')
      },
      {
        title: <IntlMessages id='admin.report.pnr' />,
        dataIndex: 'pnrNumber',
        ...this.getColumnSearchProps('pnrNumber')
      },
      {
        title: <IntlMessages id='admin.report.destinations' />,
        dataIndex: 'destinations',
        // ...this.getColumnSearchProps('destinations')
      },

      {
        title: <IntlMessages id='admin.report.cities' />,
        dataIndex: 'routing'
      },
      {
        title: <IntlMessages id='admin.report.startDate' />,
        dataIndex: 'startDate'
      },
      {
        title: <IntlMessages id='admin.report.endDate' />,
        dataIndex: 'endDate'
      },
      {
        title: <IntlMessages id='admin.report.duration' />,
        dataIndex: 'dayDiff'
      },
      {
        title: <IntlMessages id='admin.report.legStartDate' />,
        dataIndex: 'legStartDate'
      },
      {
        title: <IntlMessages id='admin.report.legEndDate' />,
        dataIndex: 'legEndDate'
      },
      {
        title: <IntlMessages id='admin.report.legDuration' />,
        dataIndex: 'legDuration'
      },
      {
        title: <IntlMessages id='admin.report.countries' />,
        dataIndex: 'countries'
      }
    ];
    if (Permissions.isRole('CSR') || Permissions.isRole('Administrator')) {
      data.splice(2, 0, {
        title: <IntlMessages id='admin.report.organization' />,
        dataIndex: 'parentOrganizationName',
        ...this.getColumnSearchProps('parentOrganizationName')
      });
    }
    return data;
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e.target.value ? e.target.value.trimLeft() : '')}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex, clearFilters, setSelectedKeys)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex, clearFilters, setSelectedKeys)}
            disabled={selectedKeys != '' && selectedKeys !== null ? false : true}
            icon='search'
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            <IntlMessages id='admin.search' />
          </Button>
          <Button
            disabled={selectedKeys != '' && selectedKeys !== null ? false : true}
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            <IntlMessages id='admin.reset' />
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex, clearFilters, setSelectedKeys) => {
    if (selectedKeys === '') {
      return false;
    }

    this.setState({ searchText: selectedKeys });
    let filters = this.state.filters;
    filters[dataIndex] = {
      val: selectedKeys,
      clearf: clearFilters,
      filter: true,
      setSelectedKeys: setSelectedKeys,
      confirm: confirm
    };
    this.setState({ filters: filters });
    this.props.updateFilters({module:this.module, filters: filters})
    confirm();
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let filters = this.state.filters;
    if (filters[dataIndex]) {
      if (filters[dataIndex].setSelectedKeys && typeof filters[dataIndex].setSelectedKeys === 'function') {
        filters[dataIndex].setSelectedKeys('');
        filters[dataIndex].confirm();
      }
    }
    //filters[dataIndex] = { val: '', clearf: '', filter: false };
    delete filters[dataIndex]
    this.setState({ filters: filters });
    this.setState({ searchText: '' });
  };



  handleTableChange = (pagination, filters, sorter, key) => {
    this.setState({ activetravellerList: [] });
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    if(this.state.clearAllFilters == true){
      filters = {};
      let filters2 = this.state.filters;
      for (let filter2 in filters2) {
        if (filters2.hasOwnProperty(filter2)) {
          if (filters2[filter2].setSelectedKeys && typeof filters2[filter2].setSelectedKeys === 'function') {
            filters2[filter2].setSelectedKeys('');
            filters2[filter2].confirm();
          }
        }
      }
      this.setState({filterInputs: {}});
    } else {
      let _filters = this.state.filters;
      let result = {};
      for (let key1 in _filters) {
          if (_filters.hasOwnProperty(key1)) {
            let newKey = key1;
            result[newKey] = (_filters[key1].val) ? _filters[key1].val : _filters[key1];
          }
      }
      filters = result;
    }
    
    filters.startDate = this.state.startdate;
    filters.endDate = this.state.enddate;
    filters.countryCode = this.state.countryId;

    // if (Object.keys(this.state.filters).length == 0) {
    //   filters = {};
    //   filters.startDate = this.state.startdate;
    //   filters.endDate = this.state.enddate;
    //   filters.countryCode = this.state.countryId;
    // } else {
    //   filters.startDate = this.state.startdate;
    //   filters.endDate = this.state.enddate;
    //   filters.countryCode = this.state.countryId;
    // }

    this.setState({ loading: true, csvSearchFilters: filters, filterInputs: filters });
    this.props
      .getTravelersByDestandTime(
        orgId,
        userRole,
        {
          filters: filters,
          pagination: {
            page: pagination.current,
            pageSize: pagination.pageSize
          },
          sorter: sorter
        },
        parseInt(key) ? key : this.state.tab
      )
      .then((resp) => {
        pager.total = resp.result.totalElement;
        this.setState({
          activetravellerList: resp.result.content,
          allactivetravellerList: resp.result.content,
          downloadSelectedValue: 'current',
          pagination: pager,
          showTable: true,
          loading: false,
          clearAllFilters: false
        });
      })
      .catch((ex) => {
        this.setState({ loading: false, clearAllFilters: false });
      });
  };

  handleManualTableChange = (pagination, filters, sorter) => {
    this.setState({ activeManualtravellerList: [] });
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    
    if(this.state.clearAllFilters == true){
      filters = {};
      let filters2 = this.state.filters;
      for (let filter2 in filters2) {
        if (filters2.hasOwnProperty(filter2)) {
          if (filters2[filter2].setSelectedKeys && typeof filters2[filter2].setSelectedKeys === 'function') {
            filters2[filter2].setSelectedKeys('');
            filters2[filter2].confirm();
          }
        }
      }
      this.setState({filterInputs: {}});
    } else {
      let _filters = this.state.filters;
      let result = {};
      for (let key1 in _filters) {
          if (_filters.hasOwnProperty(key1)) {
            let newKey = key1;
            result[newKey] = (_filters[key1].val) ? _filters[key1].val : _filters[key1];
          }
      }
      filters = result;
    }
    
    
    filters.startDate = this.state.startdate;
    filters.endDate = this.state.enddate;
    filters.countryCode = this.state.countryId;
    this.setState({ loading: true, csvSearchFilters: filters, filterInputs: filters });
    this.props
      .getManualTravelersByDestandTime(orgId, userRole, {
        filters: filters,
        pagination: { page: pagination.current, pageSize: pagination.pageSize },
        sorter: sorter
      })
      .then((resp) => {
        pager.total = resp.result.totalElement;
        this.setState({
          activeManualtravellerList: resp.result.content,
          allactivetravellerList: resp.result.content,
          downloadSelectedValue: 'current',
          pagination: pager,
          showTable: true,
          loading: false,
          clearAllFilters: false
        });
      })
      .catch((ex) => {
        this.setState({ loading: false,  clearAllFilters: false});
      });
  };

  disabledEndDate = (e) => {
    const { startdate } = this.state;
    var endval = moment(e._d).format('YYYY-MM-DD');
    if (!endval || !startdate) {
      return false;
    }
    return endval.valueOf() <= startdate.valueOf();
  };

  onCsvDownloadSelect = (key) => {
    this.setState({ downloadSelectedValue: key });
    if (key === 'all') {
      this.getCsvData();
    }
    //
  };
  getCsvData = () => {
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    this.props
      .getAllTravelersByDestandTime(orgId, userRole, {
        filters: this.state.csvSearchFilters,
        pagination: { page: 1, pageSize: 1000 },
        sorter: {}
      })
      .then((resp) => {
        this.setState({
          allactivetravellerList: resp.result.content
        });
      })
      .catch((ex) => {});
  };

  handleCombineAndSubmit = () => {
    var self = this;
    this.handleSubmit();
    setTimeout(function afterTwoSeconds() {
      self.getMagnatechorCombinedCsv('combined');
    }, 2000);
  };

  getManualCsv = () => {
    let token = UserServices.getToken();
    let _headers = { 'Content-Type': 'multipart/form-data' };
    if (token) _headers['authorization'] = token ? 'Bearer ' + token : undefined;

    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    let filename = 'Manual Traveler by destination and time Report.csv';
    try {
      axios({
        url: travellerapi.getManualTravelersByDestandTimeCsv(orgId, userRole, {
          filters: this.state.csvSearchFilters,
          pagination: { page: 1, pageSize: 10 },
          sorter: {}
        }),
        method: 'GET',
        responseType: 'blob',
        headers: _headers
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      console.log(e);
    }
  };

  getUnmappedUserCsv = () => {
    let token = UserServices.getToken();
    let _headers = { 'Content-Type': 'multipart/form-data' };
    if (token) _headers['authorization'] = token ? 'Bearer ' + token : undefined;

    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    let filename = 'Unmapped Traveler by destination and time Report.csv';
    try {
      axios({
        url: travellerapi.getUnmappedTravelersByDestandTimeCsv(orgId, userRole, {
          filters: this.state.csvSearchFilters,
          pagination: { page: 1, pageSize: 10 },
          sorter: {}
        }),
        method: 'GET',
        responseType: 'blob',
        headers: _headers
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      console.log(e);
    }
  };

  getMagnatechorCombinedCsv = (type) => {
    let token = UserServices.getToken();
    let _headers = { 'Content-Type': 'multipart/form-data' };
    if (token) _headers['authorization'] = token ? 'Bearer ' + token : undefined;
    let currentUser = UserService.getUser();
    let orgId = currentUser.companyID;
    let userRole = currentUser.role;
    if (type == 'magnatech') {
      let filename = 'Automated Traveler by destination and time Report.csv';
      try {
        axios({
          url: travellerapi.getMagnatechTravelersByDestandTimeCsv('magnatech', orgId, userRole, {
            filters: this.state.csvSearchFilters,
            pagination: { page: 1, pageSize: 10 },
            sorter: {}
          }),
          method: 'GET',
          responseType: 'blob',
          headers: _headers
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) {
        console.log(e);
      }
    } else if (type == 'nonCap') {
      let filename = 'Non CAP Traveler by destination and time Report.csv';
      try {
        axios({
          url: travellerapi.getMagnatechTravelersByDestandTimeCsv('nonCap', orgId, userRole, {
            filters: this.state.csvSearchFilters,
            pagination: { page: 1, pageSize: 10 },
            sorter: {}
          }),
          method: 'GET',
          responseType: 'blob',
          headers: _headers
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      let filename = 'Combined Traveler by destination and time Report.csv';
      try {
        axios({
          url: travellerapi.getMagnatechTravelersByDestandTimeCsv('combined', orgId, userRole, {
            filters: this.state.csvSearchFilters,
            pagination: { page: 1, pageSize: 10 },
            sorter: {}
          }),
          method: 'GET',
          responseType: 'blob',
          headers: _headers
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) {
        console.log(e);
      }
    }
  };
  render() {
    const children = [];
    // for (let i = 10; i < 36; i++) {
    //   children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    // }
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.props;
    const formItemLayout =
      formLayout === 'horizontal'
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 }
          }
        : null;
    let _state = this.state;
    let _this = this;
    var traveller = this.state.allactivetravellerList;
    let headers;
    this.state.role === 'Administrator' || this.state.role === 'CSR'
      ? (headers = [
          { label: 'Name', key: 'name' },
          { label: 'Divison', key: 'divisionName' },
          { label: 'Organization', key: 'parentOrganizationName' },
          { label: 'PNR', key: 'pnrNumber' },
          { label: 'City', key: 'city' },
          { label: 'Departs', key: 'departureDate' },
          { label: 'Routing', key: 'routing' }
        ])
      : (headers = [
          { label: 'Name', key: 'name' },
          { label: 'Divison', key: 'divisionName' },
          { label: 'PNR', key: 'pnrNumber' },
          { label: 'City', key: 'city' },
          { label: 'Departs', key: 'departureDate' },
          { label: 'Routing', key: 'routing' }
        ]);

    let filtertag = Object.keys(this.state.filters).map(function(key1) {
      let keyLabel = _this.getHeaderKeys().find((el) => el.dataIndex === key1);

      if (keyLabel && keyLabel.title && keyLabel.title.props && keyLabel.title.props.id) {
        keyLabel = <IntlMessages id={keyLabel.title.props.id} />;
      } else {
        keyLabel = key1;
      }

      if (_state.filters[key1].filter) {
        return (
          <span>
            <Button key={key1} type='primary' onClick={() => _this.handleReset(_state.filters[key1].clearf, key1)}>
              {keyLabel} : {_state.filters[key1].val}
              <Icon type='close' />
            </Button>
            &nbsp;
          </span>
        );
      }
    });
    return (
      <div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              <IntlMessages id='admin.sidebar.submenu.travelersbydestdate' />
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={16} lg={12} xl={12} className='align-right'>
            <ul>
              <li>
                {' '}
                {/* <Select value={this.state.downloadSelectedValue} style={{ width: 200 }} onChange={this.onCsvDownloadSelect} >
                    <Option value="current">Current Page</Option>
                    <Option value="all">All</Option>
              </Select> */}{' '}
                {/* <CSVLink filename={"travellerByDestandDateReport.csv"} headers={headers} data={traveller} >
                  <Button>
                    <Icon type="download" />
                    <span><IntlMessages id="admin.report.csvdownload" /></span>
                  </Button>
                </CSVLink> */}
              </li>
              <li></li>
              <li>
                {/* <PrintComponents trigger={<Button type="primary" >
                  <i
                    style={{ display: "inline-block", marginRight: "5px" }}
                    className="fas fa-print"
                  ></i>
                  <span><IntlMessages id="admin.report.print" /></span>
                </Button> }> <PrintAbleComponent headerKeys={this.getHeaderKeys()} data={this.state.activetravellerList}/> </PrintComponents> */}
              </li>
              <li>
                <Button type='primary' htmlType='button' onClick={() => this.props.history.push('../')}>
                  <IntlMessages id='admin.userlisting.back' />
                </Button>
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <Form layout={formLayout}>
          <Row gutter={24} style={{ marginBottom: '20px' }}>
            <Col xs={7}>
              <Form.Item {...formItemLayout}>
                {getFieldDecorator('todate', {
                  rules: [
                    {
                      required: true,
                      message: <IntlMessages id='admin.input.required'></IntlMessages>
                    }
                  ]

                  //initialValue: this.state.rangedate && this.state.rangedate.length === 2 ? this.state.rangedate : this.state.rangedate
                })(
                  <RangePicker
                    onChange={(e) => this.handleStartDateChange(e)}
                    style={{ width: '300px', marginTop: '20px' }}
                    disabled={
                      /* this.state.formexpired || (this.state.itineraryData.fromDate && this.state.itineraryData.fromDate !== null) &&  */ this
                        .state.formexpired ||
                      this.state.resultsFound ||
                      (this.props.form.getFieldValue('flightnumber') !== null &&
                        this.props.form.getFieldValue('flightnumber') !== undefined &&
                        this.props.form.getFieldValue('flightnumber') !== '' &&
                        this.state.resultsFound)
                    }
                    //disabledDate={disabledDate}

                    format={dateFormat}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={5}>
              <Form.Item {...formItemLayout}>
                {getFieldDecorator('countryName', {
                  rules: [
                    {
                      required: false,
                      message: <IntlMessages id='admin.input.required'></IntlMessages>
                    }
                  ]
                })(
                  <Select
                    showSearch
                    //placeholder={<IntlMessages id="admin.report.country" />}
                    placeholder='All Countries'
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => this.handleCountryChange(e)}
                    style={{ width: '200px', marginTop: '20px' }}
                    defaultValue='All Countries'
                    allowClear
                  >
                    <Option key='all' value='all'>
                      All Countries
                    </Option>
                    {this.state.countries.map(function(item) {
                      return (
                        <Option key={item.countryId} value={item.countryId}>
                          {item.countryName}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Button type='primary' onClick={() => this.handleSubmit()} style={{ margin: '20px auto' }}>
                <IntlMessages id='admin.organization.submit'></IntlMessages>
              </Button>
            </Col>
            <Col xs={6}>
              <Button style={{ marginTop: '20px', marginLeft: '30px' }} onClick={this.handleCombineAndSubmit}>
                <Icon type='export' />
                <span>
                  <IntlMessages id='admin.report.csvdownloadMagandManual' />
                </span>
              </Button>
            </Col>
          </Row>
        </Form>
        <div id='printableArea'>
          {this.state.showTable ? (
            <div>
              <hr />

              <div className='card-container'>
                <Tabs type='card' onChange={this.callback}>
                  <TabPane tab={<IntlMessages id='admin.report.manual' />} key='1'>
                    <Row gutter={24} style={{ marginBottom: '20px' }}>
                      <Col xs={24}>{filtertag}</Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xs={15}></Col>
                      <Col xs={6}>
                        <Button style={{ marginBottom: '10px', marginLeft: '88px' }} onClick={this.getManualCsv}>
                          <Icon type='export' />
                          <span>
                            <IntlMessages id='admin.report.csvdownloadManual' />
                          </span>
                        </Button>
                      </Col>
                      <Col xs={3}>
                        <PrintComponents
                          trigger={
                            <Button type='primary' style={{ marginLeft: '34px' }}>
                              <i
                                style={{
                                  display: 'inline-block',
                                  marginRight: '5px'
                                }}
                                className='fas fa-print'
                              ></i>
                              <span>
                                <IntlMessages id='admin.report.print' />
                              </span>
                            </Button>
                          }
                        >
                          {' '}
                          <PrintAbleComponent
                            headerKeys={this.getHeaderKeys().filter((data) => data.dataIndex !== 'pnrNumber')}
                            data={this.state.activeManualtravellerList}
                          />{' '}
                        </PrintComponents>
                      </Col>
                    </Row>
                    <Row gutter={24}></Row>
                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ overflowX: 'auto' }}>
                        <Table
                          bordered
                          columns={this.getHeaderKeys()}
                          dataSource={this.state.activeManualtravellerList}
                          pagination={this.state.pagination}
                          loading={this.state.loading}
                          onChange={this.handleManualTableChange}
                          filterInputValues={this.state.filterInputs}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab={<IntlMessages id='admin.report.magnatech' />} key='2'>
                    <Row gutter={24} style={{ marginBottom: '20px' }}>
                      <Col xs={24}>{filtertag}</Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xs={15}></Col>
                      <Col xs={6}>
                        <Button
                          style={{ marginBottom: '10px', marginLeft: '63px' }}
                          onClick={() => this.getMagnatechorCombinedCsv('magnatech')}
                        >
                          <Icon type='export' />
                          <span>
                            <IntlMessages id='admin.report.csvdownloadMagnatech' />
                          </span>
                        </Button>
                      </Col>
                      <Col xs={3}>
                        <PrintComponents
                          trigger={
                            <Button type='primary' style={{ marginLeft: '30px' }}>
                              <i
                                style={{
                                  display: 'inline-block',
                                  marginRight: '5px'
                                }}
                                className='fas fa-print'
                              ></i>
                              <span>
                                <IntlMessages id='admin.report.print' />
                              </span>
                            </Button>
                          }
                        >
                          {' '}
                          <PrintAbleComponent
                            headerKeys={this.getHeaderKeys()}
                            data={this.state.activetravellerList}
                          />{' '}
                        </PrintComponents>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ overflowX: 'auto' }}>
                        <Table
                          bordered
                          columns={this.getHeaderKeys2()}
                          dataSource={this.state.activetravellerList}
                          pagination={this.state.pagination}
                          loading={this.state.loading}
                          onChange={this.handleTableChange}
                          filterInputValues={this.state.filterInputs}
                         // id = {'automated'}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  {(Permissions.isRole('CSR') || Permissions.isRole('Administrator')) && (
                    <TabPane tab={<IntlMessages id='admin.report.nonCap' />} key='3'>
                      <Row gutter={24} style={{ marginBottom: '20px' }}>
                        <Col xs={24}>{filtertag}</Col>
                      </Row>
                      <Row gutter={24}>
                        <Col xs={15}></Col>
                        <Col xs={6}>
                          <Button
                            style={{ marginBottom: '10px', marginLeft: '63px' }}
                            onClick={() => this.getUnmappedUserCsv('nonCap')}
                          >
                            <Icon type='export' />
                            <span>
                              <IntlMessages id='admin.report.csvdownloadMagnatech' />
                            </span>
                          </Button>
                        </Col>
                        <Col xs={3}>
                          <PrintComponents
                            trigger={
                              <Button type='primary' style={{ marginLeft: '30px' }}>
                                <i
                                  style={{
                                    display: 'inline-block',
                                    marginRight: '5px'
                                  }}
                                  className='fas fa-print'
                                ></i>
                                <span>
                                  <IntlMessages id='admin.report.print' />
                                </span>
                              </Button>
                            }
                          >
                            {' '}
                            <PrintAbleComponent
                              headerKeys={this.getHeaderKeys3()}
                              data={this.state.activetravellerList}
                            />{' '}
                          </PrintComponents>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ overflowX: 'auto' }}>
                          <Table
                            bordered
                            columns={this.getHeaderKeys3()}
                            dataSource={this.state.activetravellerList}
                            pagination={this.state.pagination}
                            loading={this.state.loading}
                            onChange={this.handleTableChange}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  )}
                </Tabs>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...travellersReportActions, ...adminActions, ...PaginationActions },
    dispatch
  );
}
const WrappedForm = Form.create({ name: "travelerdest-report" })(
  TravelersbyDestDate
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    WrappedForm
  )
);
